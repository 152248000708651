import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Category from "../components/Category";
import About from "../components/About";
import Social from "../components/Social";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";


const Hero = styled.h1`
    padding: 20px;
    background-image: url("https://ik.imagekit.io/dnddecpho/airvitamin/Air%20Vitamin_-dMqEE_hn.jpg?updatedAt=1728393364012");
    background-position: center;
    background-size: cover;
    height: 60vh;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;

    ${mobile({
        height: "20vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${"https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_AboutUs_1__EH8QCe46nr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669805318773"})`,
    })}

    ${tablet({
        height: "40vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${"https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_AboutUs_1__EH8QCe46nr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669805318773"})`,
    })}
`;

// Note that Swiper component is for mobile only while Product component is for desktop only, both replacing the other

const Home = () => {
    return (
        <div>
            <Navbar />
            <Hero></Hero>
            <Category />
            <About />
            <Social/>
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Home;
