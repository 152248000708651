import { useSelector } from "react-redux";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import { mobile } from "../responsive";
import { useEffect, useState } from "react";
import moment from "moment";
import { publicRequest } from "../requestMethods";
import { addWarrantyToProduct } from "../redux/apiCalls";

const Container = styled.div``;

const Wrapper = styled.div``;

const Title = styled.h1`
    padding: 20px;
    background-image: url("https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_HomeBanner4_VL1HD10Qn.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669805042166");
    background-position: center;
    height: 20rem;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-transform: capitalize;
    font-size: 3rem;
    ${mobile({
        fontSize: "1.5rem",
        height: "14rem",
        backgroundSize: "cover",
        backgroundImage: `url(${"https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_HomeBanner4_1__RShH6cD2Z.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669805318705"})`,
    })}
`;

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.25;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 10;
`;

const ModalTitle = styled.h1`
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    padding: 0 8rem;
    ${mobile({ padding: "0 3rem" })}
`;

const Close = styled.div`
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 2rem;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background: black;
    height: 65%;
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 102;
    position: absolute;
    ${mobile({ width: "100%", height: "100%" })}
`;

const Form = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;

const Input = styled.input`
    flex: 1;
    padding: 15px;
    margin: 1rem 0;
    text-align: center;
`;

const ModalButton = styled.button`
    border: none;
    padding: 15px 20px;
    background-color: teal;
    color: white;
    cursor: pointer;
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    ${mobile({ flexDirection: "column" })}
`;

const Info = styled.div`
    display: block;
    margin: 0 8rem;
    flex: 3;
    ${mobile({ margin: "0rem", display: "flex", flexDirection: "column" })}
`;

const Product = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 4rem 0;
    ${mobile({ flexDirection: "column", margin: "2rem 0" })}
`;

const ProductDetail = styled.div`
    flex: 2;
    display: flex;
    justify-content: center;
    ${mobile({ flexDirection: "column", alignItems: "center" })}
`;

const Image = styled.img`
    width: 200px;
`;

const Details = styled.div`
    margin-left: 30px;
    width: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    ${mobile({ display: "flex", alignItems: "center" })}
`;

const ProductName = styled.span`
    ${mobile({ margin: "0.5rem 0", textAlign: "center" })}
`;

const ProductId = styled.span`
    ${mobile({ margin: "0.5rem 0" })}
`;

const Hr = styled.hr`
    background-color: #eee;
    border: none;
    height: 1px;
`;

const Order = () => {
    const [order, setOrder] = useState(undefined);
    const [modalData, setModalData] = useState(true);
    const [serialCode, setSerialCode] = useState(undefined);
    const [warrantyModalState, setWarrantyModalState] = useState(false);
    const currentUser = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser._id : undefined
    );

    const checkUserStatus = () => {
        alert("Please login first to add your warranty. Thank you!");
        window.location.href = "/login";
    };

    useEffect(() => {
        currentUser === undefined
            ? checkUserStatus()
            : console.log("logged in");

        window.scrollTo(0, 0);
        const getOrder = async () => {
            try {
                const res = await publicRequest.get(
                    "/orders/find/" + currentUser
                );
                setOrder(res.data);
            } catch {}
        };
        getOrder();
    }, [currentUser]);

    const handleClick = () => {
        try {
            try {
                addWarrantyToProduct({ serialCode, modalData, currentUser });
            } catch (err) {
                alert("Warranty is not valid / is already in use");
            }
        } catch (err) {
            alert("Warranty is not valid / is already in use");
        }
    };

    return (
        <Container>
            <Navbar />
            <Announcement />
            <Wrapper>
                <Title></Title>
                <Bottom>
                    <Info>
                        {order !== undefined ? (
                            order.map((order, i) => (
                                <Product key={i}>
                                    <ProductDetail>
                                        <Image src={order.img} />
                                        <Details>
                                            <ProductName>
                                                {order.title}
                                            </ProductName>
                                            <ProductId>
                                                <b>Purchased Item:</b>{" "}
                                                {order.code}
                                            </ProductId>
                                            <ProductId>
                                                <b>Purchased Date:</b>{" "}
                                                {moment(order.createdAt).format(
                                                    "DD/MM/YYYY"
                                                )}
                                            </ProductId>
                                        </Details>
                                    </ProductDetail>
                                </Product>
                            ))
                        ) : (
                            <></>
                        )}
                        <Hr />
                    </Info>
                </Bottom>
            </Wrapper>
            <Newsletter />
            <Footer />
            {warrantyModalState && (
                <>
                    <ModalContainer></ModalContainer>
                    <Overlay>
                        <ModalDetails>
                            <Close onClick={() => setWarrantyModalState(false)}>
                                X
                            </Close>
                            <ModalTitle>
                                Add Warranty to {modalData.title}
                            </ModalTitle>
                            <Form>
                                <Input
                                    onChange={(e) =>
                                        setSerialCode(e.target.value)
                                    }
                                    placeholder="Serial Code"
                                />
                                <ModalButton onClick={handleClick}>
                                    Add Warranty
                                </ModalButton>
                            </Form>
                        </ModalDetails>
                    </Overlay>
                </>
            )}
        </Container>
    );
};

export default Order;
