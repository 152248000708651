import styled from "styled-components";
import { mobile, tablet } from "../responsive";


const Container = styled.div`
    height: 65vh;
    background-color: #ac997b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${mobile({ padding: "0 2rem 0 2rem", height: "60vh" })}
    ${tablet({ padding: "0 2rem 0 2rem", height: "60vh" })}
`;
const Title = styled.h1`
    font-family: FuturaPT-Bold;
    letter-spacing: 0.3rem;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    ${mobile({ fontSize: "1.6rem", margin: "0px" })}
    ${tablet({ fontSize: "1.2rem", margin: "0px" })}
`;

const Desc = styled.div`
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 20px;
    text-align: center;
    ${mobile({ textAlign: "center", fontSize: "1rem", margin: "20px 0" })}
    ${tablet({ textAlign: "center", fontSize: "1rem", margin: "20px 0" })}
`;
const Button = styled.button`
    width: 20%;
    margin: 25px 0;
    padding: 15px;
    border: 2px solid #1f2f4d;
    background-color: black;
    color: white;
    cursor: pointer;
    font-family: FuturaPT-Bold;

    &:hover {
        background-color: white;
        border: 2px solid transparent;
        color: #ac997b;
    }
    ${mobile({ width: "100%", margin: "1rem 0" })}
    ${tablet({ width: "100%", margin: "1rem 0" })}
`;

const Newsletter = () => {
    return (
        <Container>
            <Title>SUBSCRIBE CHANNEL</Title>
            <Desc>
                We are thrilled to invite you to join our brand-new Telegram
                channel!{" "}
            </Desc>
            <Desc>
                This is your chance to stay connected and get the latest
                updates, special offers, and exclusive content directly from us.
            </Desc>
            <Desc>  We can't wait to connect with you and share all the exciting things we have in store!✨✨</Desc>
            <Button onClick={() => window.open("https://t.me/+URgyZjgLl45lZTU1", "_blank") }>
                Join Channel
            </Button>
        </Container>
    );
};

export default Newsletter;
