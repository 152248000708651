import styled from "styled-components";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column", paddingTop: "4rem" })}
    ${tablet({ padding: "0rem 0", display: "flex", flexDirection: "column" })}
`;
const Title = styled.h1`
    padding: 0 2rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    color: #ac997b;
    text-align: center;
    margin: 0rem 0rem 0rem 0;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "0 2rem",
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "0 2rem",
    })}
`

const AboutContainer = styled.div`
    display: flex;
    justify-content: center;
    ${mobile({ display: "block" })}
    ${tablet({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.6rem;
    font-weight: 300;
    margin-top: 1.5rem;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
    ${tablet({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    items-align: center;
    order: "1"
    ${mobile({ height: "50vh" })}
    ${tablet({ height: "100%" , paddingTop: "6rem" })}
`;

const LeftB = styled.div`
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    items-align: center;
    order: "1"
    ${mobile({ height: "50vh" })}
    ${tablet({ height: "100%" ,paddingTop: "6rem", paddingBottom: "6rem" })}
`;


const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/SSR/1728190905259g10.png_-_1728192705_yldpmMDnn");
    background-size: contain;
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
        transform: scale(1.1);
        -webkit-transition: transform 0.6s ease-in-out;
    }
    ${mobile({
        margin: "0rem",
        height: "40vh",
        width: "100vw",
    })}
    ${tablet({
        margin: "0rem",
        height: "50vh",
        width: "100vw",
    })}
`;

const ImageB = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/1901%E6%96%B0%E7%89%88%E8%AF%A6%E6%83%85_05_soL3iIBuO.png?updatedAt=1693905375689");
    background-size: contain;
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
        transform: scale(1.1);
        -webkit-transition: transform 0.6s ease-in-out;
    }
    ${mobile({
        margin: "0rem",
        order: "2",
        height: "40vh",
        width: "100vw",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
    })}
    ${tablet({
        margin: "0rem",
        order: "2",
        height: "50vh",
        width: "100vw",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
    })}
`;

const ImageC = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/1901%E6%96%B0%E7%89%88%E8%AF%A6%E6%83%85_23_UK7uFvdPg.png?updatedAt=1693905375622");
    background-size: contain;
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
        transform: scale(1.1);
        -webkit-transition: transform 0.6s ease-in-out;
    }
    ${mobile({
        display: "none"
    })}
    ${tablet({
        display: "none"
    })}
`;

const ImageD = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/1901%E6%96%B0%E7%89%88%E8%AF%A6%E6%83%85_23_UK7uFvdPg.png?updatedAt=1693905375622");
    background-size: contain;
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
        transform: scale(1.1);
        -webkit-transition: transform 0.6s ease-in-out;
    }
    ${mobile({
        display: "none"
    })}
    ${tablet({
        display: "none"
    })}
`;

const About = () => {
    return (
        <>
            <Container>
                <LeftA>
                    <Title>Health vs Wealth</Title>
                    <AboutContainer>
                        <Desc>
                        Many people strive for financial stability, yet health challenges persist despite efforts to eat well and exercise. Rising medical costs often complicate this struggle
                        </Desc>
                    </AboutContainer>
                </LeftA>
                <ImageA></ImageA>
            </Container>
            <Container>
                <ImageB></ImageB>
                <LeftA>
                    <Title>Overlooked Factors</Title>
                    <AboutContainer>
                        <Desc>
                        Our daily lives are filled with various forms of radiation from devices like smartphones, Wi-Fi routers, and electrical appliances, which may have unknown long-term health effects.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <LeftB>
                    <Title>Immune System Strain</Title>
                    <AboutContainer>
                        <Desc>
                        The constant exposure to bacteria, viruses, and other pathogens can overwhelm our immune systems. While we rest at night, our bodies are still fighting off these microorganisms, potentially leading to chronic stress on our immune systems.
                        </Desc>
                    </AboutContainer>
                </LeftB>
                <ImageC></ImageC>
            </Container>
            <Container>
                <ImageD></ImageD>
                <LeftA>
                    <Title>Air Pollution</Title>
                    <AboutContainer>
                        <Desc>
                        Beyond particulate matter, air quality is affected by chemical ionization and electromagnetic waves, introducing various harmful elements into our environment. This pollution can exacerbate health issues and weaken our overall well-being.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
        </>
    );
};

export default About;
